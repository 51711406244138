
import {
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonPage,
    IonRow,
    IonSlide,
    IonSlides,
} from "@ionic/vue";
import { defineComponent } from "vue";

export default defineComponent({
    name: "WelcomePage",
    components: {
        IonButton,
        IonCol,
        IonContent,
        IonGrid,
        IonPage,
        IonRow,
        IonSlide,
        IonSlides,
    },
    setup() {
        const slideOpts = {
            effect: "flip",
        };
        return { slideOpts };
    },
});
