<template>
    <ion-page>
        <ion-content>
            <ion-slides pager="true" options="slideOpts">
                <ion-slide class="slide">
                    <div class="skip-button">
                        <ion-button fill="clear" class="ion-color-light" router-link="/clubs">Skip</ion-button>
                    </div>
                    <ion-grid>
                        <ion-row class="upper-row">
                            <ion-col size="12">
                                <img src="@/assets/images/logos/logoClubBuzzer_v3.svg" />
                            </ion-col>
                        </ion-row>
                        <ion-row class="lower-row">
                            <ion-col size="12">
                                <h1>1. Finde Vereine!</h1>
                                <span>Finde Vereine, die dich interessieren!</span>
                            </ion-col>
                            <ion-col size="12">
                                <div class="ion-padding ion-margin">
                                    <ion-button
                                        color="primary"
                                        fill="outline"
                                        class="btnSubmit ion-text-uppercase ion-padding-horizontal"
                                    >Jetzt Vereine finden</ion-button>
                                </div>
                            </ion-col>
                        </ion-row>
                    </ion-grid>
                </ion-slide>
                <ion-slide class="slide">
                    <div class="skip-button">
                        <ion-button fill="clear" class="ion-color-light" router-link="/clubs">Skip</ion-button>
                    </div>
                    <ion-grid>
                        <ion-row class="upper-row">
                            <ion-col size="12">
                                <img src="@/assets/images/logos/logoClubBuzzer_v3.svg" />
                            </ion-col>
                        </ion-row>
                        <ion-row class="lower-row">
                            <ion-col size="12">
                                <h1>2. Abonniere Vereine!</h1>
                                <span>Wähle die Vereine, Verbände und Sportbünde aus, über deren Neuigkeiten du informiert werden möchtest!</span>
                                <div class="ion-padding ion-margin">
                                    <ion-button
                                        color="primary"
                                        fill="outline"
                                        class="btnSubmit ion-text-uppercase ion-padding-horizontal"
                                    >Jetzt Vereine finden</ion-button>
                                </div>
                            </ion-col>
                        </ion-row>
                    </ion-grid>
                </ion-slide>
                <ion-slide class="slide">
                    <div class="skip-button">
                        <ion-button fill="clear" class="ion-color-light" router-link="/clubs">Skip</ion-button>
                    </div>
                    <ion-grid>
                        <ion-row class="upper-row">
                            <ion-col size="12">
                                <img src="@/assets/images/logos/logoClubBuzzer_v3.svg" />
                            </ion-col>
                        </ion-row>
                        <ion-row class="lower-row">
                            <ion-col size="12">
                                <h1>3. Wähle die Kanäle!</h1>
                                <span>Wähle für jeden abonnierten Verein, Verband oder Sportbund aus, aus welchen Kanälen du über Neuigkeiten informiert werden möchtest!</span>
                            </ion-col>
                        </ion-row>
                    </ion-grid>
                </ion-slide>
                <ion-slide class="slide">
                    <div class="skip-button">
                        <ion-button fill="clear" class="ion-color-light" router-link="/clubs">Skip</ion-button>
                    </div>
                    <ion-grid>
                        <ion-row class="upper-row">
                            <ion-col size="12">
                                <img src="@/assets/images/logos/logoClubBuzzer_v3.svg" />
                            </ion-col>
                        </ion-row>
                        <ion-row class="lower-row">
                            <ion-col size="12">
                                <h1>FERTIG!</h1>
                                <span>
                                    Anschließend kannst du in den News der von dir
                                    ausgewählten Vereine, Verbände und
                                    Sportbünde stöbern!
                                </span>
                                <div class="ion-padding ion-margin">
                                    <ion-button
                                        color="primary"
                                        class="btnSubmit ion-text-uppercase ion-padding-horizontal"
                                    >Jetzt Vereine finden</ion-button>
                                </div>
                            </ion-col>
                        </ion-row>
                    </ion-grid>
                </ion-slide>
            </ion-slides>
        </ion-content>
    </ion-page>
</template>

<script lang="ts">
import {
    IonButton,
    IonCol,
    IonContent,
    IonGrid,
    IonPage,
    IonRow,
    IonSlide,
    IonSlides,
} from "@ionic/vue";
import { defineComponent } from "vue";

export default defineComponent({
    name: "WelcomePage",
    components: {
        IonButton,
        IonCol,
        IonContent,
        IonGrid,
        IonPage,
        IonRow,
        IonSlide,
        IonSlides,
    },
    setup() {
        const slideOpts = {
            effect: "flip",
        };
        return { slideOpts };
    },
});
</script>

<style scoped>
ion-slides {
    height: 100%;
    width: 100%;
}

.slide {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    flex-direction: column;
    color: var(--ion-color-primary);
    background-color: var(--ion-color-primary-contrast);
}

img {
    max-width: 80%;
}

ion-row {
    height: 50%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
}

ion-grid {
    width: 100%;
}

.upper-row {
    align-content: flex-end;
}
.lower-row {
    align-content: space-between;
}

.skip-button {
    position: absolute;
    top: 20px;
    right: 20px;
}
</style>